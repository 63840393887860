import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { placeholder, lazyload, AdvancedImage } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { FeaturedBenefitStep } from '../../../../../../types/featuredBenefitBlockType';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import {
  Heading,
  Description,
  Top,
  Wrapper,
  Tag,
  ButtonGroup,
  Content,
  HeadingBlock,
} from './StyledFeaturedBenefitBlock';
import { FeaturedBenefitBlockTypeProps } from './FeaturedBenefitBlock';
import BlockContent from '../../../block-content/BlockContent';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';

export const VerticalWithList: React.FC<FeaturedBenefitBlockTypeProps> = ({ block }) => {
  const { title, description, steps, primaryCta, secondaryCta, colorVariant, tag } = block;

  return (
    <StyledWrapper $variant={colorVariant}>
      <StyledContent>
        <Left>
          <Tag $styleVariant={colorVariant}>{tag}</Tag>
          <Heading variant={TextVariant.Display}>
            <HeadingBlock value={title} />
          </Heading>
          {description && <Description>{description}</Description>}
          <div style={{ marginTop: spacing.space40 }} />
          <ButtonGroup primaryCta={primaryCta} secondaryCta={secondaryCta} colorVariant={colorVariant} />
        </Left>
        <Right>
          <Column>
            {steps?.map((step) => (
              <Step key={step.title} step={step} />
            ))}
          </Column>
        </Right>
      </StyledContent>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Wrapper)`
  padding: ${spacing.space80} ${spacing.space40};
  @media (max-width: ${breakpoints.m}) {
    padding: ${spacing.space40} 0;
  }
`;

const StyledContent = styled(Content)`
  display: flex;
  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    padding: 0;
    max-width: 100vw;
  }
`;

const Left = styled(Top)`
  align-items: start;
  width: fit-content;
  max-width: 45%;
  padding-right: ${spacing.space80};

  @media (max-width: ${breakpoints.l}) {
    padding-right: ${spacing.space32};
  }
  @media (max-width: ${breakpoints.m}) {
    width: unset;
    max-width: unset;
    padding: 0 ${spacing.space24};
  }
`;

const Right = styled.div`
  max-width: 45%;
  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    max-width: unset;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space32};

  @media (max-width: ${breakpoints.m}) {
    gap: ${spacing.space8};
    flex-direction: row;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    & > div:first-child {
      margin-left: ${spacing.space24};
    }

    & > div:last-child {
      margin-right: ${spacing.space24};
    }
  }
`;

export const Step: React.FC<{ step: FeaturedBenefitStep }> = ({ step }) => {
  const cld = useCloudinary();
  const { title, description, stepImage, illustration, link } = step;
  const devicePixelRatio = useDevicePixelRatio();
  const target = useDocumentUrlWithNode(link?.data) || '';

  const cldImage = useMemo(
    () =>
      stepImage?.publicId
        ? cld
            .image(stepImage?.publicId)
            .delivery(dpr(devicePixelRatio))
            .quality('auto')
            .format(illustration ? 'svg' : 'auto')
        : undefined,
    [stepImage, cld, devicePixelRatio, illustration],
  );

  const renderImageWithLink = () => {
    if (link?.type === 'internalLink') {
      return (
        <Link to={target}>
          <StyledAdvancedImage
            alt={stepImage?.altText || ''}
            cldImg={cldImage}
            plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), placeholder({ mode: 'blur' })]}
          />
        </Link>
      );
    }

    if (link?.type === 'link') {
      return (
        <a href={step?.link?.data?.href} target={step?.link ? '_blank' : '_self'} rel="noopener noreferrer">
          <StyledAdvancedImage
            alt={stepImage?.altText || ''}
            cldImg={cldImage}
            plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), placeholder({ mode: 'blur' })]}
          />
        </a>
      );
    }

    return (
      <StyledAdvancedImage
        alt={stepImage?.altText || ''}
        cldImg={cldImage}
        plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), placeholder({ mode: 'blur' })]}
      />
    );
  };

  return (
    <StyledStep>
      <IconWrapper>{cldImage && renderImageWithLink()}</IconWrapper>
      <TextContent>
        <Text variant={TextVariant.Header2}>{title}</Text>
        {/* Ensure backwards compatability as we move from string to block content. */}
        {typeof description === 'string' ? (
          <Text variant={TextVariant.BodyText}>{description}</Text>
        ) : (
          <BlockContent value={description} />
        )}
      </TextContent>
    </StyledStep>
  );
};

const StyledStep = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
    min-width: calc(100% - 48px);
    scroll-snap-align: center;
  }
`;

const IconWrapper = styled.div`
  margin-right: ${spacing.space32};
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${breakpoints.m}) {
    margin: 0;
    min-width: 40px;
  }
`;

const TextContent = styled.div`
  padding-right: ${spacing.space32};
`;

const StyledAdvancedImage = styled(AdvancedImage)`
  max-width: 100%;

  @media (max-width: ${breakpoints.m}) {
    max-width: 50%;
  }
`;
